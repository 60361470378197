import React from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'

function ButtonCustom({
    title,
    bgcolor,
    colorText,
    onClick,
    bgHover,
    isLoading
}) {
    return isLoading ? (
        <LoadingButton
            loading
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
            sx={{
                // padding: '5px',
                backgroundColor: bgcolor,
                '&:hover': { background: bgHover }
            }}
        >
            {title}
        </LoadingButton>
    ) : (
        <Button
            onClick={onClick}
            sx={{
                padding: '5px',
                backgroundColor: bgcolor,
                '&:hover': { background: bgHover }
            }}
            variant='contained'
        >
            {title}
        </Button>
    )
}

export default ButtonCustom
