import http from 'views/utilities/http'

export const URL_MONEY_DEPOSIT = '/me/deposit'
export const URL_MONEY_WITHDRAW = '/me/payment'
export const URL_EXCHANGE_POINT = '/point/change/input'
const actionMoney = {
    depositMoney(body) {
        return http.post(URL_MONEY_DEPOSIT, body)
    },
    withdrawMoney(body) {
        return http.post(URL_MONEY_WITHDRAW, body)
    },
    exchangePoint(body) {
        return http.post(URL_EXCHANGE_POINT, body)
    }
}

export default actionMoney
