import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { handleMiddlewareLoadingAction } from 'store/helper'
import agentNoteApi from 'apis/agentNote'

const getAgentNoteData = createAsyncThunk('get/agentNote', (query) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.getAgentNoteHistory(query),
        'get/agentNote'
    )
)
const getAgentNoteDetail = createAsyncThunk('get/noteDetail', (query) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.getAgentNoteDetail(query),
        'get/noteDetail'
    )
)
const deleteInqiry = createAsyncThunk('delete/inqiry', (id) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.deleteInqiry(id),
        'delete/inqiry'
    )
)

const deleteMessage = createAsyncThunk('delete/message', (id) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.deleteMessage(id),
        'delete/message'
    )
)

const chatting = createAsyncThunk('chatting/message', (data) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.chatting(data),
        'chatting/message'
    )
)

const stopChat = createAsyncThunk('chatting/stopChat', (data) =>
    handleMiddlewareLoadingAction(
        () => agentNoteApi.stopChat(data),
        'chatting/stopChat'
    )
)

const agentNoteSlice = createSlice({
    name: 'agentNoteList',
    initialState: {
        noteList: undefined,
        noteDetail: undefined,
        isLoadingDetail: false
    },
    reducers: {
        resetNoteDetail: (state, action) => {
            state.noteDetail = undefined
        }
    },
    extraReducers: {
        [getAgentNoteData.fulfilled]: (state, action) => {
            state.noteList = action.payload
        },
        [getAgentNoteDetail.pending]: (state, action) => {
            state.isLoadingDetail = true
        },
        [getAgentNoteDetail.fulfilled]: (state, action) => {
            state.noteDetail = action.payload?.data
            state.isLoadingDetail = false
        }
    }
})

export const thunkActions = {
    getAgentNoteData,
    getAgentNoteDetail,
    deleteInqiry,
    deleteMessage,
    chatting,
    stopChat
}
export const { resetNoteDetail } = agentNoteSlice.actions
export default agentNoteSlice.reducer
