/* eslint-disable prettier/prettier */
// assets
import {
    IconBrandChrome,
    IconHelp,
    IconDeviceDesktop,
    IconUsers,
    IconFileCheck,
    IconChartAreaLine,
    IconArrowBack,
    IconChartBar,
    IconAdjustmentsAlt,
    IconDeviceMobileMessage
} from '@tabler/icons'
import { path } from 'constants/path'

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconDeviceDesktop,
    IconUsers,
    IconFileCheck,
    IconChartAreaLine,
    IconArrowBack,
    IconChartBar
}

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const MainMenu = {
    id: 'Main Menu',
    type: 'group',
    children: [
        {
            id: 'DeviceDesktop',
            title: 'メイン',
            type: 'item',
            url: path.dashboard,
            icon: icons.IconDeviceDesktop,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: '会員管理',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'userList',
                    title: '会員リスト',
                    type: 'item',
                    url: path.userList,
                    breadcrumbs: false
                }
                // {
                //     id: 'userConnector',
                //     title: '接続者リスト',
                //     type: 'item',
                //     url: path.userConnector,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'userRollingConversion',
                //     title: 'ポイント転換',
                //     type: 'item',
                //     url: path.userRollingConversion,
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'betting',
            title: '収益レポート',
            type: 'collapse',
            icon: icons.IconFileCheck,

            children: [
                {
                    id: 'slot',
                    title: 'スロット収益',
                    type: 'item',
                    url: '/betting/slot'
                },
                {
                    id: 'casino',
                    title: 'カジノ収益',
                    type: 'item',
                    url: '/betting/casino'
                    // breadcrumbs: false
                },
                {
                    id: 'slot2',
                    title: '全体収益',
                    type: 'item',
                    url: '/betting/slot&casino'
                    // breadcrumbs: false
                }
            ]
        },
        {
            id: 'history',
            title: 'ゲーム記録統計',
            type: 'collapse',
            icon: icons.IconChartAreaLine,

            children: [
                {
                    id: 'historySlot',
                    title: '日付別記録',
                    type: 'item',
                    url: path.historySlot
                },
                {
                    id: 'historyLive',
                    title: '日付別 ゲーム',
                    type: 'item',
                    url: path.historyLive
                },
                {
                    id: 'historyUser',
                    title: '日付別会員',
                    type: 'item',
                    url: path.historyUser
                },
                {
                    id: 'historyShop',
                    title: '日付別店舗',
                    type: 'item',
                    url: path.historyShop
                },
                {
                    id: 'historyAllGame',
                    title: '全体ゲーム',
                    type: 'item',
                    url: path.historyAllGame
                },
                {
                    id: 'historyAllUser',
                    title: '全体会員別',
                    type: 'item',
                    url: path.historyAllUser
                }
                // {
                //     id: 'historyDate',
                //     title: '일자별 게임별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyUser',
                //     title: '일자별 사용자별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyAll',
                //     title: '전체 게임별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'historyAllTotal',
                //     title: '전체 사용자별',
                //     type: 'item',
                //     url: '/betting-page',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'management-betting',
            title: 'ベット管理',
            type: 'collapse',
            icon: IconAdjustmentsAlt,
            children: [
                {
                    id: 'management-betting',
                    title: 'カジノ、スロット全体内訳',
                    type: 'item',
                    url: path.managementBettingAll
                },
                {
                    id: 'management-betting',
                    title: 'カジノ、スロット的中内訳',
                    type: 'item',
                    url: path.managementBettingWin
                },
                {
                    id: 'management-betting',
                    title: 'カジノ、スロット未当選内訳',
                    type: 'item',
                    url: path.managementBettingLose
                },
                {
                    id: 'management-betting',
                    title: 'カジノ、スロットキャンセル内訳',
                    type: 'item',
                    url: path.managementBettingCancel
                },
                {
                    id: 'management-betting',
                    title: 'カジノ内訳',
                    type: 'item',
                    url: path.managementBettingCasino
                },
                {
                    id: 'management-betting',
                    title: 'スロット内訳',
                    type: 'item',
                    url: path.managementBettingSlot
                }
            ]
        },
        {
            id: 'agent-note',
            title: '1:1 相談',
            type: 'item',
            icon: IconDeviceMobileMessage,
            url: path.agentNote
        },
        // {
        //     id: 'losing',
        //     title: 'ルージング精算',
        //     type: 'collapse',
        //     icon: icons.IconChartBar,

        //     children: [
        //         {
        //             id: 'losing',
        //             title: 'ルージング精算統計',
        //             type: 'item',
        //             url: path.losing
        //             // breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'logout',
            title: 'ログアウト',
            type: 'item',
            url: '',
            icon: icons.IconArrowBack,
            breadcrumbs: false
        }
    ]
}

export default MainMenu
