import http from 'views/utilities/http'

const agentNoteEndpoint = 'io-777/cscenter'
const deleteInqiry = `cscenter/delete`
const deleteMessage = `message/delete`
const chatting = 'io-777/cscenter/chatting'
const stopChat = 'io-777/cscenter/stop-chatting'

const agentNoteApi = {
    getAgentNoteHistory: (query) =>
        http.get(`${agentNoteEndpoint}`, { params: query }),
    getAgentNoteDetail: (id) => http.get(`${agentNoteEndpoint}/${id}`),
    deleteInqiry: (id) => http.get(`${deleteInqiry}/${id}`),
    deleteMessage: (id) => http.get(`${deleteMessage}/${id}`),
    chatting: (data) => http.post(chatting, data),
    stopChat: (data) => http.post(stopChat, data)
}

export default agentNoteApi
