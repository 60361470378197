import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToastControl } from 'views/utilities/toastControl'
import {
    getDeposit,
    getIP,
    getMessage
} from 'views/pages/UserHistory/userHistory.slice'
import { formatDate } from 'views/utilities/formatDate'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        minWidth: '500px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}))

// eslint-disable-next-line react/prop-types
export default function ModalMessage({
    open,
    setOpen,
    title,
    content,
    action,
    params,
    me
}) {
    const handleClose = () => {
        setOpen(false)
    }
    const handleWithdraw = () => {
        action()
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby='responsive-dialog-title'
                open={open}
            >
                <Typography
                    variant='h3'
                    sx={{ m: 0, p: 2 }}
                    id='customized-dialog-title'
                >
                    {title}
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        padding: '40px',
                        minWidth: '200px',
                        textAlign: 'center'
                    }}
                >
                    <Typography>{content}</Typography>
                </Box>
            </BootstrapDialog>
        </>
    )
}
