/* eslint-disable no-undef */
import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

const EditorCommon = ({
    initialValue,
    handleOnChange,
    name,
    disabled,
    toolbar = ' bold italic  bullist numlist | ',
    toolbar2,
    menubar = false,
    statusbar = false,
    required = false
}) => {
    const editorRef = useRef(null)
    return (
        <Editor
            apiKey='sfidxzh6aa7v8rn7o1ap8kcb5ifjk22qlbodkj8aijzqh5j7'
            ref={editorRef}
            disabled={disabled}
            textareaName={name}
            onEditorChange={handleOnChange}
            value={initialValue}
            init={{
                plugins:
                    'preview importcss searchreplace autolink directionality code visualblocks fullscreen  image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap  ',
                toolbar1:
                    'undo redo | bold italic underline |   blocks | alignleft aligncenter alignright |  numlist bullist | forecolor backcolor removeformat | emoticons | fullscreen searchreplace | insertfile image  link anchor',
                min_height: 150,
                statusbar,
                menubar,
                entity_encoding: 'raw',
                file_picker_callback(cb, value, meta) {
                    const inputFile = document.createElement('input')
                    inputFile.setAttribute('type', 'file')
                    inputFile.setAttribute('accept', 'image/*')
                    inputFile.onchange = (e) => {
                        if (inputFile.files) {
                            const file = inputFile.files[0]
                            const reader = new FileReader()
                            reader.addEventListener('load', () => {
                                const id = `blobid${new Date().getTime()}`
                                if (editorRef.current) {
                                    const { blobCache } =
                                        editorRef.current.editor.editorUpload
                                    if (reader.result) {
                                        const base64 = reader.result
                                            .toString()
                                            .split(',')[1]
                                        const blobInfo = blobCache.create(
                                            id,
                                            file,
                                            base64
                                        )
                                        cb(blobInfo.blobUri(), {
                                            title: file.name
                                        })
                                    }
                                    reader.readAsDataURL(file)
                                }
                            })
                        }
                    }
                    inputFile.click()
                }
            }}
        />
    )
}

export default EditorCommon
