import * as React from 'react'
import Button from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, TextField } from '@mui/material'
import ButtonCustom from 'ui-component/button/ButtonCustom'
import { formatNumber } from 'views/utilities/moneyConvert'
import { useEffect } from 'react'
import { helpAgent } from 'store/modal.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useToastControl } from 'views/utilities/toastControl'
import EditorCommon from 'ui-component/editor/editor'
import CkEditorCustomized from 'ui-component/editor/CkEditor'
import { thunkActions } from 'views/pages/1-1History/agent.slice'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    },
    '& .MuiPaper-root': {
        maxWidth: '800px'
    }
}))

// eslint-disable-next-line react/prop-types
export default function ModalHelp({ open, setOpen, me }) {
    const [value, setValue] = React.useState('')
    const theme = useTheme()
    const { toastControl } = useToastControl()
    const { helpStatus } = useSelector((state) => state.modalReducer)
    const [dataEditor, setDataEditor] = React.useState('')
    const handleOnChangeEditor = (value, editor) => {
        setDataEditor(value)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleInputChange = (e) => {
        setValue(e)
    }
    const dispatch = useDispatch()

    const handleSendRequest = () => {
        if (dataEditor !== '') {
            dispatch(
                helpAgent({
                    title: value,
                    contents: dataEditor
                })
            ).then((res) => {
                if (res?.payload?.result) {
                    setValue('')
                    const query = { page: 1, limit: 30 }
                    dispatch(thunkActions.getAgentNoteData(query))
                }
            })
        }
    }

    const lang = {
        중복등록입니다: '重複登録で',
        '등록이 완료되었습니다.': '登録が完了しました。',
        '중복등록입니다.': 'すでに登録済みのお問い合わせです。'
    }

    useEffect(() => {
        if (helpStatus) {
            if (helpStatus?.status === 200) {
                toastControl(true, 'success', lang[helpStatus?.message])
                setOpen(false)
            }
            if (helpStatus?.status !== 200) {
                toastControl(true, 'error', lang[helpStatus?.message])
            }
        }
    }, [helpStatus])
    return (
        <>
            {me.data && (
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby='responsive-dialog-title'
                    open={open}
                >
                    <Typography
                        variant='h3'
                        sx={{ m: 0, p: 2 }}
                        id='customized-dialog-title'
                    >
                        新規問い合わせ作成
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ padding: '40px', minWidth: '800px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                my: 2
                            }}
                        >
                            <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                タイトル
                            </Typography>
                            <TextField
                                id='outlined-read-only-input'
                                value={value}
                                onChange={(e) => {
                                    handleInputChange(e.target.value)
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                my: 2
                            }}
                        >
                            <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                内容
                            </Typography>
                            {/* <EditorCommon
                                handleOnChange={handleOnChangeEditor}
                            /> */}
                            <CkEditorCustomized
                                getDataProps={handleOnChangeEditor}
                            />
                        </Box>
                    </Box>

                    <DialogActions sx={{ margin: '0 auto' }}>
                        <Button
                            autoFocus
                            sx={{
                                color: '#fff',
                                backgroundColor: '#844fc1',
                                borderColor: '#844fc1',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                '&:hover': {
                                    backgroundColor: '#5f3491',
                                    borderColor: '#844fc1'
                                }
                            }}
                            onClick={handleSendRequest}
                        >
                            作成する
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            )}
        </>
    )
}
