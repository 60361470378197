import { Typography, formControlClasses } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import { formatNumber } from 'views/utilities/moneyConvert'

const InforBox = ({ me }) => {
    useEffect(() => [me])
    return (
        <>
            {me.data && (
                <>
                    <Box
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',

                            bgcolor: '#90cef5',
                            color: '#282f3a',
                            padding: '0.47rem 0.6rem',
                            lineHeight: '1.5rem'
                        }}
                    >
                        <Typography
                            sx={{ textAlign: 'start', marginBottom: '10px' }}
                            variant='h6'
                            color='inherit'
                        >
                            保有金
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            variant='h4'
                            color='inherit'
                        >
                            {formatNumber(me?.data?.mb_money || 0)}
                        </Typography>
                    </Box>
                    {/* <Box
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',

                            bgcolor: '#ffbac1',
                            color: '#282f3a',
                            padding: '0.47rem 0.6rem'
                        }}
                    >
                        <Typography
                            sx={{ textAlign: 'start', marginBottom: '10px' }}
                            variant='h6'
                            color='inherit'
                        >
                            保有ポイント
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            variant='h4'
                            color='inherit'
                        >
                            {formatNumber(me?.data?.mb_point || 0)}
                        </Typography>
                    </Box> */}
                    {/* <Box
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',

                            bgcolor: '#FFFBE4',
                            color: '#282f3a',
                            padding: '0.47rem 0.6rem',
                            lineHeight: '1.5rem'
                        }}
                    >
                        <Typography
                            sx={{ textAlign: 'start', marginBottom: '10px' }}
                            variant='h6'
                            color='inherit'
                        >
                            スロットローリング
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            variant='h4'
                            color='inherit'
                        >
                            {me?.data?.slot_rolling || 0}%
                        </Typography>
                    </Box> */}
                    {/* <Box
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',

                            bgcolor: '#E9E1F6',
                            color: '#282f3a',
                            padding: '0.47rem 0.6rem'
                        }}
                    >
                        <Typography
                            sx={{ textAlign: 'start', marginBottom: '10px' }}
                            variant='h6'
                            color='inherit'
                        >
                            カジノローリング
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            variant='h4'
                            color='inherit'
                        >
                            {me?.data?.casino_rolling || 0}%
                        </Typography>
                    </Box> */}
                    {/* <Box
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',

                            bgcolor: '#26DAD2',
                            color: '#282f3a',
                            padding: '0.47rem 0.6rem'
                        }}
                    >
                        <Typography
                            sx={{ textAlign: 'start', marginBottom: '10px' }}
                            variant='h6'
                            color='inherit'
                        >
                            ルージング
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            variant='h4'
                            color='inherit'
                        >
                            {me?.data?.slot_losing || 0}%
                        </Typography>
                    </Box> */}
                </>
            )}
        </>
    )
}
export default InforBox
