// third-party
import { combineReducers } from 'redux'

// project import

import pointChangeReducer from '../pointChangeCommon.slice'
import pointChangeSliceReducer from 'views/pages/PointChangeList/pointChangeList.slice'
// import rollingFeeReducer from '../../pages/BasicSetting/RollingFeeSettings/rollingFeeReducer.slice';
import userConnectorReducer from 'views/pages/UserConnector/userConnector.slice'
import userReducer from 'views/pages/UserList/user.slice'
import customizationReducer from '../customizationReducer'
import authReducer from '../../views/pages/authentication/authentication.slice'

//dashboard
import dashboardReducer from 'views/pages/Dashboard/dashboard.slice'

//history
import slotReducer from 'views/pages/history/Slot/slot.slice'
import liveReducer from 'views/pages/history/Live/live.slice'
import shopReducer from 'views/pages/history/Shop/shop.slice'
import allUserHistoryReducer from 'views/pages/history/User/user.slice'

//
import toastReducer from 'ui-component/Toast/toast.slice'
import bettingSliceReducer from 'views/pages/Betting&Rolling/BettingSlot.slice'
import loadReducer from '../loader.slice'
import meReducer from '../me.slice'
import modalReducer from 'store/modal.slice'
import userHistoryReducer from 'views/pages/UserHistory/userHistory.slice'
import losingHistoryReducer from 'views/pages/Losing/losing.slice'
//loader
import loaderReducer from '../loader.slice'
//

// management
import managementBettingReducer from 'views/pages/managementBetting/managementBetting.slice'
import agentNoteReducer from 'views/pages/1-1History/agent.slice'
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    // rollingFeeReducer
    pointChangeReducer,
    customization: customizationReducer,
    dashboardReducer,
    authReducer,
    slotReducer,
    liveReducer,
    userReducer,
    shopReducer,
    bettingSliceReducer,
    userConnectorReducer,
    loader: loaderReducer,
    meReducer,
    modalReducer,
    toastReducer,
    userHistoryReducer,
    allUserHistoryReducer,
    losingHistoryReducer,
    pointChangeSliceReducer,
    managementBettingReducer,
    agentNoteReducer
})

export default reducers
